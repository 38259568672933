import "@tabler/core";
import intlTelInput from 'intl-tel-input';
// import noUiSlider from 'nouislider';

window.intlTelInput = intlTelInput;
// window.noUiSlider = noUiSlider;

document.querySelectorAll('.intl-tel-input').forEach(function (input) {
    intlTelInput(input, {
        initialCountry: 'SA',
        preferredCountries: ['SA', 'AE', 'KW', 'QA', 'BH', 'OM',  ],
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.17/js/utils.js',
    });
});

document.addEventListener("DOMContentLoaded", function () {
    if (!document.getElementById('range-simple')) {
        return;
    }

    window.noUiSlider && (noUiSlider.create(document.getElementById('range-simple'), {
        start: 500,
        connect: [true, false],
        step: 50,
        range: {
            min: 0,
            max: 1000,
        }
    }));
});

